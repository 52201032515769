import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Snapshot from '@/views/Snapshot.vue'
import ShuttleSign from '@/views/ShuttleSign.vue'
import ShuttleList from '@/views/ShuttleList.vue'
import ShuttleScheduler from '@/views/ShuttleScheduler.vue'
import Dashboard from '@/views/Dashboard.vue'
import Services from '@/views/Services.vue'
import Sixmwt from '@/views/Sixmwt.vue'
import SixmwtProfile from '@/components/SixmwtProfile.vue'
import SixmwtDashboard from '@/components/SixmwtDashboard.vue'
import CheckInSheet from '@/views/CheckInSheet.vue'
import CaseManagement from '@/views/CaseManagement.vue'
import CaseDailyRecords from '@/views/CaseDailyRecords.vue'
import MaterialManagement from '@/views/MaterialManagement.vue'
import MaterialSettlement from '@/views/MaterialSettlement.vue'
import WhiteBoard from '@/views/WhiteBoard.vue'
import ContactBook from '@/views/ContactBook.vue'
import LoginStaff from '@/views/LoginStaff.vue'
import LoginUser from '@/views/LoginUser.vue'
import EmployeeAttendance from '@/views/EmployeeAttendance.vue'
import TravelDashboard from '@/views/TravelDashboard.vue'
import CaseList from '@/views/CaseList.vue'
import StaffList from '@/views/StaffList.vue'
import PrimaryCareManagement from '@/views/PrimaryCareManagement.vue'
import ReImBurseMent from '@/views/ReImBurseMent.vue'
import EmergencyContact from '@/views/EmergencyContact.vue'
import UrineManagement from '@/views/UrineManagement.vue'
import Assessments from '@/views/Assessments.vue'
import ServiceRecord from '@/views/ServiceRecord.vue'
import VitalSignRecord from '@/views/VitalSignRecord.vue'
import MedicationSafety from '@/components/MedicationSafety.vue'
import PhysicalAssessment from '@/components/PhysicalAssessment.vue'
import PressureInjuryRisk from '@/components/PressureInjuryRisk.vue'
import FallRisk from '@/components/FallRisk.vue'
import ADLAssessment from '@/components/ADLAssessment.vue'
import IADLAssessment from '@/components/IADLAssessment.vue'
import CognitiveAssessment from '@/components/CognitiveAssessment.vue'
import BSRS5 from '@/components/BSRS5.vue'
import FrailtyAssessment from '@/components/FrailtyAssessment.vue'
import PainAssessment from '@/components/PainAssessment.vue'
import NutritionAssessment from '@/components/NutritionAssessment.vue'
import CarePlan from '@/components/CarePlans.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/emergencycontact',
  },
  {
    name: 'login',
    path: '/login',
    component: LoginStaff,
    meta: { title: '登入' },
  },
  {
    path: '/whiteboard',
    component: WhiteBoard,
    meta: { title: '電子佈告欄' },
  },
  {
    path: '/case-dashboard',
    component: Main,
    meta: { title: '個案總覽' },
  },
  {
    path: '/case-management',
    component: CaseManagement,
    meta: { title: '出勤及生理數據' },
  },
  {
    path: '/case-daily-record',
    component: CaseDailyRecords,
    meta: { title: '個案日誌' },
  },
  {
    path: '/snapshot',
    component: Snapshot,
  },
  {
    path: '/material-management',
    component: MaterialManagement,
    meta: { title: '衛材登記' },
  },
  {
    path: '/material-settlement',
    component: MaterialSettlement,
    meta: { title: '每月衛材結算' },
  },
  {
    path: '/shuttle-sign',
    component: ShuttleSign,
    meta: { title: '交通車簽到' },
  },
  {
    path: '/shuttle-list',
    component: ShuttleList,
    meta: { title: '車輛管理' },
  },
  {
    path: '/shuttle-scheduler',
    component: ShuttleScheduler,
    meta: { title: '趟次規劃' },
  },
  {
    path: '/checkinsheet',
    component: CheckInSheet,
    meta: { title: '簽到退記錄' },
  },
  {
    path: '/services/:id',
    component: Services,
  },
  {
    path: '/services/:id/dashboard',
    component: Dashboard,
  },
  {
    path: '/services/:id/sixmwt',
    component: Sixmwt,
    children: [
      {
        path: '',
        component: SixmwtProfile,
        name: 'SixmwtProfile',
      },
      {
        path: 'sixmwtdashboard',
        component: SixmwtDashboard,
        name: 'SixmwtDashboard',
      },
    ],
  },
  {
    path: '/employee-attendance',
    component: EmployeeAttendance,
    meta: { title: '差勤系統' },
  },
  {
    path: '/travel-dashboard',
    component: TravelDashboard,
    meta: { title: '旅遊儀錶板' },
  },
  {
    path: '/case-list',
    component: CaseList,
    meta: { title: '個案列表' },
  },
  {
    path: '/staff-list',
    component: StaffList,
    meta: { title: '員工列表' },
  },
  {
    path: '/care-management',
    component: PrimaryCareManagement,
    meta: { title: '主責照服管理' },
  },
  {
    name: 'contactbook-login',
    path: '/contactbook/login',
    component: LoginUser,
    meta: { title: '家屬聯絡簿登入' },
  },
  {
    name: 'contactbook',
    path: '/contactbook/home',
    component: ContactBook,
    meta: { title: '家屬聯絡簿' },
  },
  {
    name: 'reimbursement',
    path: '/reimbursement',
    component: ReImBurseMent,
    meta: { title: '核銷報表' },
  },
  {
    name: 'emergencycontact',
    path: '/emergencycontact',
    component: EmergencyContact,
    meta: { title: '急診聯繫單' },
  },
  {
    name: 'urine-management',
    path: '/urine-management',
    component: UrineManagement,
    meta: { title: '尿液檢測' },
  },
  {
    name: 'service-record',
    path: '/service-record',
    component: ServiceRecord,
    meta: { title: '個案日誌活動紀錄表' },
  },
  {
    name: 'vital-sign-record',
    path: '/vital-sign-record',
    component: VitalSignRecord,
    meta: { title: '生命徵象月報表' },
  },
  {
    path: '/assessment',
    component: Assessments,
    children: [
      {
        path: 'medication-safety',
        component: MedicationSafety,
        name: 'MedicationSafety',
        meta: { title: '藥物安全性評估' },
      },
      {
        path: 'physical-assessment',
        component: PhysicalAssessment,
        name: 'PhysicalAssessment',
        meta: { title: '身體評估' },
      },
      {
        path: 'pressure-injury-risk',
        component: PressureInjuryRisk,
        name: 'PressureInjuryRisk',
        meta: { title: '壓力性損傷危險評估' },
      },
      {
        path: 'fall-risk',
        component: FallRisk,
        name: 'FallRisk',
        meta: { title: '跌倒危險性評估' },
      },
      {
        path: 'adl-assessment',
        component: ADLAssessment,
        name: 'ADLAssessment',
        meta: { title: '日常生活功能評估' },
      },
      {
        path: 'iadl-assessment',
        component: IADLAssessment,
        name: 'IADLAssessment',
        meta: { title: '工具性日常生活活動功能評估' },
      },
      {
        path: 'cognitive-assessment',
        component: CognitiveAssessment,
        name: 'CognitiveAssessment',
        meta: { title: '認知功能評估' },
      },
      {
        path: 'bsrs5',
        component: BSRS5,
        name: 'BSRS5',
        meta: { title: '簡式健康量表 (BSRS-5)' },
      },
      {
        path: 'nutrition-assessment',
        component: NutritionAssessment,
        name: 'NutritionAssessment',
        meta: { title: '簡易營養評估' },
      },
      {
        path: 'pain-assessment',
        component: PainAssessment,
        name: 'PainAssessment',
        meta: { title: '疼痛評估' },
      },
      {
        path: 'frailty-assessment',
        component: FrailtyAssessment,
        name: 'FrailtyAssessment',
        meta: { title: '衰弱評估' },
      },
      {
        path: 'care-plans',
        component: CarePlan,
        name: 'CarePlan',
        meta: { title: '照護計畫' },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const isStaffLogin = sessionStorage.getItem('isStaffLogin')
  const isUserLogin = sessionStorage.getItem('isUserLogin')
  const isContactBook = to.path.startsWith('/contactbook/')
  const isContactBookId = !to.path.endsWith('/login')

  const publicPaths = ['/emergencycontact']

  document.title = `${to.meta.title} - AST Senior Living Genie`
  if (publicPaths.includes(to.path)) {
    next()
    return
  }

  if (isContactBook) {
    if (to.path === '/contactbook/login') {
      if (isUserLogin) {
        // let id = sessionStorage.getItem('id')
        next('/contactbook/home')
      } else if (!isUserLogin) {
        next()
      }
    } else if (isContactBookId) {
      if (isUserLogin) {
        next()
      } else if (!isUserLogin) {
        next('/contactbook/login')
      }
    }
  } else if (!isContactBook) {
    if (to.path === '/login') {
      if (isStaffLogin) {
        next('/whiteboard')
      } else if (!isStaffLogin) {
        next()
      }
    } else {
      if (isStaffLogin) {
        next()
      } else if (!isStaffLogin) {
        next('/login')
      }
    }
  }
})

export default router
